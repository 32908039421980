import React from 'react';

import UserProfileCard from '../components/UserProfileCard';
import ProviderCard from '../components/ProviderCard';




export default function ProfilePage() {
      return (
        <>
        <UserProfileCard />
        <ProviderCard />
        </>
        )
}
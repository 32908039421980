import React from 'react';

import BatchedNoaCard from '../components/BatchedNoaCard';


//<Route path="/batchedNoa/${providerId}/all


export default function BatchedNoaListPage() {
      return (
        <>
        <BatchedNoaCard />
        
        </>
        )
}
